import axios from "axios";
import { base_url } from "../../utils/base_url";


const uploadImg = async (data, config2) => {
  const response = await axios.post(`${base_url}upload`, data, config2)
  return response.data;
};
const deleteImg = async (id) => {
  console.log(id)
  const response = await axios.delete(`${base_url}upload/delete-img/${id.id}`, id.config3)
  return response.data;
};

const uploadService = {
  uploadImg,
  deleteImg
}
export default uploadService;