import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getEnquiries = async (enquiryData) => {

  const response = await axios.get(`${base_url}enquiry/`, enquiryData);
  return response.data
};

const deleteEnquiry = async (id) => {

  const response = await axios.delete(`${base_url}enquiry/${id.e}`, id.config3);
  return response.data
};

const getEnquiry = async (id) => {
  const response = await axios.get(`${base_url}enquiry/${id.getEnqId}`, id.config3);
  return response.data
};
const updateEnquiry = async (enq) => {

  const response = await axios.put(`${base_url}enquiry/${enq.data.id}`, { status: enq.data.enqData },
    enq.config3);
  return response.data
};

const enquiryService = {
  getEnquiries, deleteEnquiry, getEnquiry, updateEnquiry
};
export default enquiryService;