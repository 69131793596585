import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getUsers = async (config3) => {

  const response = await axios.get(`${base_url}user/all-users`, config3);
  return response.data
};
const deleteAUser = async (id) => {
  const response = await axios.delete(`${base_url}user/${id.e}`, id.config3)
  return response.data
}

const customerService = {
  getUsers,
  deleteAUser
};
export default customerService;