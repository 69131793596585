import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { getOrders, updateAOrder } from '../features/auth/authSlice';
import { Link } from 'react-router-dom';
const columns = [
  {
    title: 'SNo',
    dataIndex: 'key',
  },
  {
    title: 'User Info',
    dataIndex: 'name',
    render: (text, record) => (
      <div className='p-0 m-0'>
        <p>{text}</p>
        <p>{record.email}</p>
        <p>{record.mobile}</p>
      </div>
    )
  },
  {
    title: 'Product',
    dataIndex: 'product',
  },
  {
    title: 'Bill Amount',
    dataIndex: 'amount',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: (a, b) => a.name.length - b.name.length
  },
  {
    title: "Shipping Info",
    dataIndex: "shipping",
    render: (text, record) => (
      <div className='p-0 m-0'>
        <p>{text}</p>
        <p>{record.address}</p>
        <p>{record.other}</p>
        <p>{record.shipmobile}</p>
      </div>
    )
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];
const Orders = () => {
  const getTokenFromLocalStorage = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const config3 = {
    headers: {
      Authorization: `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
        }`,
      Accept: "application/json",
    },
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrders(config3))
    //eslint-disable-next-line
  }, [])
  const orderState = useSelector((state) => state?.auth?.orders?.orders)
  const data1 = [];
  for (let i = 0; i < orderState?.length; i++) {
    data1.push({
      key: i + 1,
      name: orderState[i]?.user?.firstname + " " + orderState[i]?.user?.lastname,
      email: orderState[i]?.user?.email,
      mobile: orderState[i]?.user?.mobile,
      product:
        <Link to={`/admin/order/${orderState[i]?.id}`}>View Orders</Link>,

      amount: orderState[i]?.totalPriceAfterDiscount,
      date: new Date(orderState[i].createdAt).toLocaleString(),
      shipping:
        `${orderState[i]?.shippingInfo?.firstname} ${orderState[i]?.shippingInfo?.lastname}`,
      address:
        `${orderState[i]?.shippingInfo?.address}, ${orderState[i]?.shippingInfo?.city}, ${orderState[i]?.shippingInfo?.state}`,
      other:
        `${orderState[i]?.shippingInfo?.other}, ${orderState[i]?.shippingInfo?.pincode}`,
      shipmobile:
        `${orderState[i]?.shippingInfo?.mobile}`,
      action:
        (
          <>
            <div className={`text-center ${orderState[i]?.orderStatus === 'Rejected' || orderState[i]?.orderStatus === 'Payment Pending'
              ? 'unsuccess'
              : orderState[i]?.orderStatus === 'Delivered'
                ? 'success'
                : orderState[i]?.orderStatus === 'On Hold' || orderState[i]?.orderStatus === 'Out For Delivery' || orderState[i]?.orderStatus === 'Paid Processing'
                  ? 'processing'
                  : ''
              }`}>
              {orderState[i]?.id}
            </div>

            <select name='' defaultValue={orderState[i]?.orderStatus} onChange={(e) => {
              const selectedStatus = e.target.value;
              if (selectedStatus === "Delivered") {
                // Assuming you have the totalPrice and couponCode available in the component state or props
                updateOrderStatus(orderState[i]?.id, selectedStatus, orderState[i]?.totalPrice, orderState[i]?.couponCode);
              } else {
                updateOrderStatus(orderState[i]?.id, selectedStatus);
              }
            }} className='form-control form-select' id="">
              <option value="Payment Pending" disabled selected>Payment Pending</option>
              <option value="Paid Processing" disabled={orderState[i]?.orderStatus === "Delivered"}>
                Paid Processing
              </option>
              <option value="On Hold" disabled={orderState[i]?.orderStatus === "Delivered"}>
                On Hold
              </option>
              <option value="Out For Delivery" disabled={orderState[i]?.orderStatus === "Delivered"}>
                Out For Delivery
              </option>
              <option value="Delivered">Delivered</option>
              <option value="Rejected" disabled={orderState[i]?.orderStatus === "Delivered"}>
                Rejected
              </option>
            </select>
            <div className='text-center'>
              Coupon Applied: <br />
              <span style={{ color: orderState[i]?.couponCode ? 'navy' : 'inherit', fontWeight: orderState[i]?.couponCode ? 'bold' : 'normal' }}>
                {orderState[i]?.couponCode ? orderState[i].couponCode : "None"}
              </span>
            </div>


          </>
        ),
    });
  }
  const updateOrderStatus = (orderId, orderStatus, totalPrice, couponCode) => {
    // Assuming you have the config3 object available in the component state or props
    const config = config3;

    // Create a payload object with the basic data (orderId and orderStatus)
    const payload = {
      id: orderId,
      status: orderStatus,
    };

    // If the selected status is "Delivered," add totalPrice and couponCode to the payload
    if (orderStatus === "Delivered") {
      payload.totalPrice = totalPrice;
      payload.couponCode = couponCode;
    }

    // Dispatch the updateAOrder action with the payload and config
    dispatch(updateAOrder({ payload, config }));
  };


  return (
    <div>
      <h3 className='mb-4 title'>Orders</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  )
}
export default Orders;
