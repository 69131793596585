import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getCoupons = async (couponData) => {
  const response = await axios.get(`${base_url}coupon/`, couponData);
  return response.data
};
const createCoupons = async (coupon) => {
  const response = await axios.post(`${base_url}coupon/`, coupon.values, coupon.config3)
  return response.data
}
const getCoupon = async (id) => {
  const response = await axios.get(`${base_url}coupon/${id.getCouponId}`, id.config3)
  return response.data
}
const deleteCoupon = async (id) => {
  const response = await axios.delete(`${base_url}coupon/${id.e}`, id.config3)
  return response.data
}
const updateCoupon = async (coupon) => {
  const response = await axios.put(`${base_url}coupon/${coupon.data.id}`,
    { name: coupon.data.couponData.name, expiry: coupon.data.couponData.expiry, discount: coupon.data.couponData.discount },
    coupon.config3
  );
  console.log(coupon)
  return response.data;
};

const CouponService = {

  getCoupons,
  createCoupons, getCoupon, deleteCoupon, updateCoupon

};
export default CouponService;