import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getColors = async () => {
  const response = await axios.get(`${base_url}color/`);
  return response.data
};
const createColor = async (color) => {
  const response = await axios.post(`${base_url}color/`, color.values, color.config3)
  return response.data
}
const getColor = async (id) => {
  const response = await axios.get(`${base_url}color/${id.getColorId}`, id.config3)
  return response.data
}
const deleteColor = async (id) => {
  const response = await axios.delete(`${base_url}color/${id.e}`, id.config3)
  return response.data
}
const updateColor = async (color) => {
  const response = await axios.put(`${base_url}color/${color.data.id}`,
    { title: color.data.colorData.title },
    color.config3
  );
  return response.data;
};

const ColorService = {

  getColors,
  createColor,
  deleteColor,
  updateColor, getColor

};
export default ColorService;