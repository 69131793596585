import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getProductCategories = async () => {
  const response = await axios.get(`${base_url}category/`);
  return response.data
};


const createCategory = async (category) => {
  const response = await axios.post(`${base_url}category/`, category.values, category.config3);
  return response.data
};

const getProductCategory = async (id) => {
  const response = await axios.get(`${base_url}category/${id.getPCatId}`, id.config3)
  return response.data
}
const deleteProductCategory = async (id) => {
  const response = await axios.delete(`${base_url}category/${id.e}`, id.config3)
  return response.data
}
const updateProductCategory = async (category) => {

  const response = await axios.put(`${base_url}category/${category.data.id}`,
    { title: category.data.pCatData.title },
    category.config3
  );
  return response.data;
};
const pcategoryService = {

  getProductCategories, createCategory, deleteProductCategory, getProductCategory, updateProductCategory

};
export default pcategoryService;